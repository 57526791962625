<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
<!--      
      <div class="col-xxl-8">
        <ListWidget9></ListWidget9>
      </div> -->

  
      <!-- <div class="col-xl-8">
        <AdvancedTableWidget2></AdvancedTableWidget2>
      </div> -->

      <div v-if="lista_permissoes_filial['ra_Filial']" class="col-lg-12 col-xxl-12">
        <protocoloDestinatarios></protocoloDestinatarios>
      </div>

      <!-- <div class="col-xxl-6">
        <ListWidget14></ListWidget14>
      </div>
      <div class="col-xxl-6">
        <ListWidget15></ListWidget15>
      </div>

      <div class="col-xxl-4">
        <ListWidget8></ListWidget8>
      </div>
      <div class="col-xxl-8">
        <ListWidget2></ListWidget2>
      </div> -->
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
// import ListWidget2 from "@/view/content/widgets/list/Widget2.vue";
import protocoloDestinatarios from "@/view/components/protocolo/protocoloDestinatarios.vue";
// import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
// import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
// import ListWidget14 from "@/view/content/widgets/list/Widget14.vue";
// import ListWidget15 from "@/view/content/widgets/list/Widget15.vue";

export default {
  name: "dashboard",
  components: {
    // AdvancedTableWidget2,
    // ListWidget2,
    protocoloDestinatarios,
    // ListWidget8,
    // ListWidget9,
    // ListWidget14,
    // ListWidget15,
    
  },
  mounted() {
  },
  computed: {
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    // id_filial_selecionada() {
    //   return this.$store.state.sistema.id_filial_selecionada;
    // },

    
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    trocar_pagina(rota) {
      this.$router.push({ name: rota });
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
