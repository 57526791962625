var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.filtro_listagem),callback:function ($$v) {_vm.filtro_listagem=$$v},expression:"filtro_listagem"}},[_c('v-radio',{attrs:{"label":"Meus protocolos","value":_vm.usuid}}),_c('v-radio',{attrs:{"label":"Protocolos Gerais","value":0}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-combobox',{attrs:{"label":"Selecione a Filial de Origem","items":_vm.lista_filials,"item-text":"label","item-value":"id"},model:{value:(_vm.filtro_filial),callback:function ($$v) {_vm.filtro_filial=$$v},expression:"filtro_filial"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{attrs:{"label":"Selecione o status","items":[
            'Todos',
            'concluido',
            'Próximo do Vencimento',
            'Atrasado',
            'cancelado',
            'andamento',
          ]},model:{value:(_vm.filtro_status),callback:function ($$v) {_vm.filtro_status=$$v},expression:"filtro_status"}})],1)],1)],1),_vm._m(0),_c('div',{staticClass:"card-body pt-0 pb-3"},[_c('div',{staticClass:"tab-content"},[(_vm.lista_protocolos_tf)?_c('div',{staticClass:"table-responsive"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.lista_protocolos_tf,"items-per-page":_vm.perPage,"page":_vm.currentPage},on:{"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.origin",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-30 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label"},[_c('img',{staticClass:"h-100 align-self-end",attrs:{"src":item.origem.img,"alt":""}})])]),_c('div',[_c('div',[_vm._v(_vm._s(item.origem.name))]),_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(item.origem.setor))]),(item.tf_filial)?_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.filial.sigla)+" ")]):_vm._e()])])]}},{key:"item.destination",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-30 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label"},[_c('img',{staticClass:"h-100 align-self-end",attrs:{"src":item.img,"alt":""}})])]),_c('div',[_c('div',[_vm._v(_vm._s(item.nome_sobrenome))]),_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(item.setor.nome))]),(item.tf_filial)?_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.tf_filial.sigla)+" ")]):_vm._e()])])]}},{key:"item.status",fn:function({ item }){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2",class:{
                'label-light-success': item.protocolo.status === 1,
                'label-light-danger': item.protocolo.status === 0,
                'label-light-warning': item.protocolo.status === 2,
              }},[_vm._v(" "+_vm._s(item.status1)+" ")])]}},{key:"item.tipoAtendimento",fn:function({ item }){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2",class:{
                'label-light-info': item.protocolo.tipo_atendimento === 1,
                'label-light-primary': item.protocolo.tipo_atendimento === 2,
              }},[_vm._v(" "+_vm._s(item.status2)+" ")])]}},{key:"item.actions",fn:function({ item }){return _vm._l((_vm.lista_btn),function(btn,index){return _c('v-tooltip',{key:index,attrs:{"top":"","color":btn.color},scopedSlots:_vm._u([(
                  true
                  // item.protocolo.status == 2 ? true : item.protocolo.status == 1 & item.usuario_id == usuid  ? true: false
                )?{key:"activator",fn:function({ on, attrs }){return [[(_vm.condicoes(_vm.usuid, btn, item))?[(_vm.lista_permissoes_filial[btn.permisao])?_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],staticClass:"mr-1 mb-1 mt-1",attrs:{"fab":"","outlined":"","color":btn.color,"small":""},on:{"click":function($event){return _vm.acoes(item, btn.button)}}},'v-btn',attrs,false),on),[_c('i',{class:btn.icon})]):_vm._e()]:_vm._e()]]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(btn.title))])])})}}],null,false,1198693395)})],1):_vm._e()])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title align-items-start flex-column"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v("Protocolos Destinatarios")]),_c('span',{staticClass:"text-muted mt-3 font-weight-bold font-size-sm"},[_vm._v("Lista de Protocos e status andamento")])])])
}]

export { render, staticRenderFns }